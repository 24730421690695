<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          Login
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <section id="firebaseui-auth-container"></section>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12" class="mt-4 text-center">
              <v-btn color="black" dark x-large @click="appleSignIn()">
                <v-icon left>mdi-apple</v-icon>
                Sign in with Apple
              </v-btn>
            </v-col>
          </v-row>

           <!-- <v-row>
            <v-col cols="12" class="text-center">
              <v-btn color="#4267B2" dark x-large @click="facebookSignIn()">
                <v-icon left>mdi-facebook</v-icon>
                Sign in with Facebook
              </v-btn>
            </v-col>
          </v-row> -->

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success">Login</v-btn>
        </v-card-actions>

      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  import { Auth } from '../firebase/auth.js'
  import firebase from 'firebase/app' 
  var firebaseui = require('firebaseui');
  import 'firebaseui/dist/firebaseui.css'

  export default {
    data: () => ({
      email: '', 
      password: '', 
      showPassword: false,
      error: '', 
    }), 
    mounted(){
      let ui = firebaseui.auth.AuthUI.getInstance();
      
      if(!ui){
        ui = new firebaseui.auth.AuthUI(Auth);
      }

      var uiConfig = {
        signInSuccessUrl: '/', 
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID        ]
      };

      ui.start("#firebaseui-auth-container", uiConfig);
    },
    methods: {
      googleSignIn(){
        var provider = new firebase.auth.GoogleAuthProvider();
        
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile'); // https://www.googleapis.com/auth/userinfo.profile
        
        firebase.auth().signInWithRedirect(provider);
      },  
      appleSignIn(){
        var provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email')
        provider.addScope('name')
        
        Auth.signInWithPopup(provider)
          .then( result => {
            var user = result.user;
            var accessToken = result.credential.accessToken;
            var idToken = result.credential.idToken
            
            this.$router.push('/')
          })
          .catch( error => {
            var errorCode = error.error
            var errorMessage = error.message
            
            var email = error.email
            var credentials = error.credential;
          })
        
      },
    }
  }
</script>
<style scoped>

</style>